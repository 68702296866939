<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="message">
        <img src="@/assets/img/yinhao.png" alt="" />
        <div>
          <div>{{ $t("workEnvironment.WE_message") }}</div>
          <div>{{ $t("workEnvironment.WE_messageTwo") }}</div>
        </div>
      </div>
      <div class="modular">
        <div class="modularImg">
          <img src="@/assets/img/weBJ1.png" alt="" class="imgHoverBig" />
        </div>
        <div>
          <div class="title">{{ $t("workEnvironment.WE_tipOne") }}</div>
          <div class="cont">{{ $t("workEnvironment.WE_tipOneOne") }}</div>
        </div>
      </div>
      <div class="modular">
        <div>
          <div class="title">{{ $t("workEnvironment.WE_tipTwo") }}</div>
          <div class="cont">{{ $t("workEnvironment.WE_tipTwoTwo") }}</div>
        </div>
        <div class="modularImg">
          <img src="@/assets/img/weBJ2.png" alt="" class="imgHoverBig" />
        </div>
      </div>
      <div class="modular">
        <div class="modularImg">
          <img src="@/assets/img/weBJ3.png" alt="" class="imgHoverBig" />
        </div>
        <div>
          <div class="title">{{ $t("workEnvironment.WE_tipThree") }}</div>
          <div class="cont">{{ $t("workEnvironment.WE_tipThreeThree") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：人才招聘-工作环境
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-15 11:31
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "人才招聘",
          title_en: "Talent recruitment",
          path: "recruitment",
        },
        {
          title_zh: "工作环境",
          title_en: "work environment",
          path: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.outer {
  background: #f6f6f6;
  padding: 80px 310px 60px;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 78px;

  > img {
    width: 30px;
    height: 30px;
    margin-right: 40px;
  }

  > div {
    > div:nth-child(1) {
      font-size: 30px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #1a2a60;
      margin-bottom: 10px;
    }
    > div:nth-child(2) {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #444444;
      line-height: 36px;
    }
  }
}

.modular {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 61px;

  .modularImg {
    overflow: hidden;
    width: 500px !important;
    height: 300px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  > div {
    width: 55%;
    .title {
      font-size: 30px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #1a2a60;
      margin-bottom: 80px;
    }

    .cont {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #444444;
      line-height: 36px;
    }
  }
}
</style>